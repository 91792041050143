<template>
  <div class='default-table radius_bg'>
    <!--    <el-row>-->
    <!--      <el-col :span="24">-->
    <!--        <el-button icon="el-icon-plus" type="primary" @click="handleAdd"-->
    <!--                   v-if="userPermissions.indexOf('lb_schedule_create')>-1">添加排期-->
    <!--        </el-button>-->
    <!--      </el-col>-->
    <!--    </el-row>-->
    <el-table :data='dataList' highlight-current-row @sort-change='changeTableSort'
              :default-sort="{prop: 'deadline', order: 'ascending'}">
      <el-table-column type='index' label='序号' width='65' align='center'></el-table-column>
      <el-table-column label='日期' prop='date' align='center' min-width='120'></el-table-column>
      <el-table-column label='红人' prop='nickname' align='center' min-width='120' sortable='custom'></el-table-column>
      <el-table-column label='平台' prop='platform_code' align='center' min-width='140' sortable='custom'>
        <template slot-scope='{row}'>
          <span>{{ row.platform_name || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column label='选品时间' prop='start_at' align='center' min-width='140' sortable='custom'>
        <template slot-scope='{row}'>
          <span>
            {{ $utils.parseTime(row.start_at, '{y}-{m}-{d}') }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label='直播时间' prop='start_at' align='center' min-width='140' sortable='custom'>
        <template slot-scope='{row}'>
          <span>
            {{ $utils.parseTime(row.live_time, '{y}-{m}-{d}') }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label='主题' prop='theme' align='center' min-width='300'></el-table-column>
<!--      <el-table-column label='最晚初选时间' prop='deadline' align='center' min-width='160'-->
<!--                       sortable='custom'></el-table-column>-->
      <el-table-column label='发起人' prop='leader_name' align='center' min-width='140'
                       sortable='custom'></el-table-column>
      <el-table-column label='状态' prop='progress_status' align='center' min-width='100' sortable='custom'>
        <template slot-scope='{row,column}'>
          <span style="padding: 4px" :class="progressStatusMap[row[column.property]].class" >
          {{ progressStatusMap[row[column.property]].label }}

          </span>
        </template>
      </el-table-column>
      <el-table-column prop='created_at' label='创建时间'  min-width='140' sortable='custom'></el-table-column>
      <el-table-column prop='updated_at' label='修改时间'  min-width='140' sortable='custom'></el-table-column>
      <el-table-column label='操作' min-width='80' align='center' fixed='right'>
        <template slot-scope='{row}'>
          <el-button type='text' @click='showDetail(row)' icon='el-icon-edit'
                     v-if="userPermissions.indexOf('lb_kol_detail') > -1">编辑
          </el-button>
<!--          &nbsp;-->
<!--          <el-dropdown @command='handleCommand'>-->
<!--              <span class='el-dropdown-link'>-->
<!--              更多操作<i class='el-icon-arrow-down el-icon&#45;&#45;right'></i>-->
<!--              </span>-->
<!--            <el-dropdown-menu slot='dropdown'>-->
<!--              <el-dropdown-item command='export_selection' icon='el-icon-download'>导出选品</el-dropdown-item>-->
<!--              <el-dropdown-item command='export_demand' icon='el-icon-download'>导出需求</el-dropdown-item>-->
<!--              <el-dropdown-item command='delete' icon='el-icon-delete' disabled>删除</el-dropdown-item>-->
<!--            </el-dropdown-menu>-->
<!--          </el-dropdown>-->

          <!--          <el-button type='text' @click='selectProduct(row)'-->
          <!--                     v-if="userPermissions.indexOf('lb_schedule_original') > -1&&row.progress_status===2">-->
          <!--            <span>初选选品</span>-->
          <!--          </el-button>-->
          <!--          <export-btn type='text' :ref='`refExportLink_${row.id}`' :size='`small`' :lint-title='`下载表格`'-->
          <!--                      :can-export="userPermissions.indexOf('lb_schedule_ack_export')>-1&&row.progress_status===5"-->
          <!--                      @export='exportExcel(row)'></export-btn>-->
          <!--          &nbsp;<el-button type='text' @click='handleDelete(row)' title='拥有删除权限并且负责人是自己的排期才能进行删除操作，除非同时拥有修改负责人的权限'-->
          <!--                           icon='el-icon-delete'-->
          <!--                           v-if="(userPermissions.indexOf('lb_schedule_delete') > -1&& row.leader_id===userInfo.id)|| userPermissions.indexOf('lb_schedule_edit_leader')>-1">-->
          <!--              删除-->
          <!--            </el-button>-->

        </template>
      </el-table-column>
    </el-table>

    <!--    渲染分页-->
    <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                  @pagination='getList' />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'

export default {
  name: 'ScheduleTable',
  components: { ExportBtn },
  props: {
    LbType: {
      type: [Number, String],
      default() {
        return null
      }
    },
    mod: {
      type: String,
      default() {
        return 'Table'
      }
    },
    searchCondition: {
      type: Object,
      default() {
        return {}
      }
    },
    progressStatusOptions: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      total: 0,
      pagingData: {
        page_size: 10,
        current_page: 1
      },
      dataList: [],
      orderSort: { start_at: 'desc' }
    }
  },

  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    // dataList() {
    //   return this.data || []
    // }
    progressStatusMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = { label: item.label, class: item.class_name }
      })
      console.log(map)
      return map
    }
  },
  methods: {
    //导出排期选品
    async exportExcel(row) {
      try {
        let name = `直播排期确认选品表-${row.nickname}-${row.platform_name} ${this.$utils.parseTime(row.start_at, '{y}-{m}-{d}')}`
        let queryParams = {
          lb_id: row.id,
          is_ack: 'Y'
        }
        let response = await this.$api.exportLbScheduleSelection(queryParams)
        if (this.$refs[`refExportLink_${row.id}`])
          this.$refs[`refExportLink_${row.id}`].exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()
    },
    handleCommand(command) {
      this.$message('click on item ' + command)
    },
    handleAdd(data) {
      this.$emit('handleCmd', 'create', data)
    },
    showDetail(data) {
      // this.$emit('showDetail', data)
      this.$emit('handleCmd', 'detail', data)
    },
    handleDelete(data) {
      // this.$emit('handleDelete', data)
      this.$emit('handleCmd', 'delete', data)
    },
    selectProduct(row) {
      this.$emit('handleCmd', 'selection', row)
      //跳转到选品页面，并携带排期ID
      // this.$router.push({
      //   name: 'productSelection',
      //   query: {lb_id: row.id}
      // })
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.ym) {
        condition.ym = this.searchCondition.ym
      }

      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.progress_status) {
        condition.progress_status = this.searchCondition.progress_status
      }
      if (this.searchCondition.leader_id) {
        condition.leader_id = this.searchCondition.leader_id
      }
      if (this.searchCondition.leader_name) {
        condition.leader_name = this.searchCondition.leader_name
      }
      // 我参与
      if (this.mod === 'Mine') {
        condition.is_mine = 1
      }
      //区分选品类型
      if (this.LbType) {
        condition.type = this.LbType
      }
      return condition
    },
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()
    },
    async getList() {
      // this.$notify.info('查询')
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, { order: this.orderSort }, this.pagingData)
      let { list, pages } = await this.$api.getLbScheduleList(searchCondition)
      this.dataList = list
      this.total = pages.total
      // this.offset = pages.offset
      this.pagingData.current_page = pages.current_page
      this.pagingData.page_size = pages.page_size
      this.$emit('update:total', this.total)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../style/main.scss";

.unselect {
  color: #909399
}
</style>
<style>
.el-dropdown-link {
  cursor: pointer;
  color: #ff3176;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>

<template>
  <div>
    <init-loading :isInitLoading.sync='isInitLoading'></init-loading>
    <div>
      <div class='radius_bg'>
        <el-row>
          <el-col :span='24'>
            <el-form :model='searchCondition' inline>
              <!--              <el-form-item label="负责人" prop="leader_id">-->
              <!--                <UserSelect :leader-id.sync="searchCondition.leader_id"  @selected="selectedLeader"/>-->
              <!--              </el-form-item>-->
              <el-form-item label=''>
                <el-date-picker
                  v-model='searchCondition.ym'
                  type='month'
                  @change='handleQuery'
                  value-format='yyyy-MM'
                  format='yyyy年MM月'
                  placeholder='选择月份' style='width: 140px'>
                </el-date-picker>
              </el-form-item>
              <el-form-item label=''>
                <!--                <artist-search-custom :type='``' @handleSelect='selectArtist' :show-first-group='false'-->
                <!--                                      style='width: 100%' />-->
                <artist-search @handleSelect='selectArtist' style='width: 200px;display: inline;margin-right: 10px' />
              </el-form-item>
              <el-form-item label='发起人'>
                <el-input v-model='searchCondition.leader_name' clearable placeholder='发起人：支持模糊搜索'
                          @change='handleQuery'></el-input>
              </el-form-item>
              <el-form-item label='状态'>
                <el-select v-model='searchCondition.progress_status' style='width: 120px' @change='handleQuery'
                           clearable>
                  <el-option :label='option.label' :value='option.value' v-for='(option,key) in progressStatusOptions'
                             :key='key'
                             :style='`text-align: center;border-radius: 3px;margin:1px;border: #DCDFE6 1px solid;background-color:${option.color_value};width:150px;padding:0 10px`'></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type='primary' @click='handleQuery' icon='el-icon-search'>查询</el-button>
                <el-button type='primary' @click='handleAdd' icon='el-icon-plus'
                           v-if="userPermissions.indexOf('lb_kol_create')>-1">添加排期
                </el-button>
              </el-form-item>
              <el-form-item>
                <RecycleList v-if='userPermissions.indexOf("lb_kol_restore")>-1' key='recycleListKol' ref='refRecycleList' lb-type='1' @changed='handleQuery'></RecycleList>
              </el-form-item>
              <el-form-item style='float: right'>
                <span>共<b>{{ total }}</b>个排期</span>&nbsp;&nbsp;
                <el-radio-group v-model='mod'>
                  <el-radio-button label='Calendar'>日历展示</el-radio-button>
                  <el-radio-button label='Table'>列表展示</el-radio-button>
                  <el-radio-button label='Mine'>我参与的</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <div>
        <!--      日历展示-->
        <ScheduleCalendar ref='refScheduleCalendar' :search-condition.sync='searchCondition'
                          v-if="mod==='Calendar'" :lb-type='1' :progress-status-options='progressStatusOptions'
                          @dragEdit='dragEdit' @handleCmd='handleCmd'
                          :total.sync='total'
        />
        <!--      列表展示-->
        <ScheduleTable ref='refScheduleTable' :lb-type='1' :mod='mod' :data='dataList'
                       :search-condition='searchCondition'
                       v-if="mod==='Table'"
                       @handleCmd='handleCmd' :progress-status-options='progressStatusOptions'
                       :total.sync='total'
        />

        <ScheduleTable ref='refScheduleMineTable' :lb-type='1' :mod='mod' :data='dataList'
                       :search-condition='searchCondition'
                       v-if="mod==='Mine'"
                       @handleCmd='handleCmd' :progress-status-options='progressStatusOptions'
                       :total.sync='total'
        />
      </div>
    </div>

    <!--    创建直播排期-->
    <CreateEdit :visible.sync='open' @close='handleQuery' :id='currId' :default-form-data='defaultFormData' />

    <!--    日志-->
    <LbScheduleLog :info-id='currId' :visible.sync='openLog' />
    <!--    <DialogSelectDressProduct :dressRow="dressRow" @close="dressClose"-->
    <!--                              :visible.sync='openDress'></DialogSelectDressProduct>-->
  </div>

</template>

<script>
import ScheduleCalendar from './ScheduleCalendar'
import ScheduleTable from './ScheduleTable'
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import CreateEdit from '@/pages/lb/kol/CreateEdit'
import ApeDrawer from '@/components/ApeDrawer'
import LbScheduleLog from '@/pages/lb/LbScheduleLog'
import LeaderSearch from '@/pages/lb/components/LeaderSearch'
import UserSelect from '@/pages/lb/components/UserSelect'
import { mapGetters } from 'vuex'
import InitLoading from '@/components/InitLoading.vue'
// import DialogSelectDressProduct from '@/pages/lb/dress/DialogSelectDressProduct.vue'
import ArtistSearch from '@/components/artist/ArtistSearch'
import RecycleList from '@/pages/lb/components/RecycleList'


export default {
  name: 'ScheduleIndex',
  components: {
    RecycleList,
    InitLoading,
    UserSelect,
    LeaderSearch,
    LbScheduleLog,
    ApeDrawer,
    CreateEdit,
    PageHeaderLayout,
    ArtistSearchCustom,
    ScheduleCalendar,
    ScheduleTable,
    ArtistSearch

  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      total: 0,
      mod: 'Calendar',
      searchCondition: { ym: null, artist_id: null, leader_id: '', progress_status: null, mod: this.mod },//
      dataList: [],//直播数据列表
      open: false,
      dialogTitle: '直播排期',
      openLog: false,//日志模态框
      currId: null,
      orderSort: { start_at: 'desc', id: 'desc' },//默认排序规则
      defaultFormData: {},
      progressStatusOptions: [
        { label: '新创建', value: 1, color_value: '#fffff', class_name: 'status-new' },
        { label: '已发需求', value: 2, color_value: '#D0F6E0', class_name: 'status-launch' },
        { label: '初选完成', value: 3, color_value: '#DCEBFF', class_name: 'status-original' },
        { label: '确认完成', value: 4, color_value: '#FFECD0', class_name: 'status-ack' },
        { label: '锁定表格', value: 5, color_value: '#FFE4E9', class_name: 'status-lock' }
      ],
      isInitLoading: true,
      openDress: false,
      dressRow: {}
    }
  },
  watch: {
    // 'searchCondition.ym': {
    //   immediate: true,
    //   handler(val) {
    //     if (val) {
    //       let ymArr = val.split('-')
    //       this.searchCondition.year = parseInt(ymArr[0])
    //       this.searchCondition.month = parseInt(ymArr[1])
    //       this.handleQuery()
    //     }
    //   }
    // },
    mod: {
      handler(val) {
        this.searchCondition.mod = val
        this.$nextTick(() => {
          this.handleQuery()
        })
      }
    }
  },
  methods: {
    dragEdit(val) {
      if (val) {
        this.handleQuery()
      }
    },
    defaultTime() {
      const end = new Date()
      //年份选择：默认当月
      this.searchCondition.ym = end.getFullYear() + '-' + (end.getMonth() + 1)
    },

    selectArtist(selectInfo) {
      if (selectInfo) {
        this.searchCondition.artist_id = selectInfo.id
        this.searchCondition.nickname = selectInfo.nickname
        this.searchCondition.group_id = selectInfo.group_id
        this.searchCondition.group_name = selectInfo.group_name
      } else {
        this.searchCondition.artist_id = null
        this.searchCondition.nickname = null
        this.searchCondition.group_id = null
        this.searchCondition.group_name = null
      }

      this.handleQuery()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.ym) {
        condition.ym = this.searchCondition.ym
      }
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.progress_status) {
        condition.progress_status = this.searchCondition.progress_status
      }
      if (this.searchCondition.leader_id) {
        condition.leader_id = this.searchCondition.leader_id
      }
      if (this.searchCondition.leader_name) {
        condition.leader_name = this.searchCondition.leader_name
      }

      return condition
    },
    handleQuery() {
      if (this.mod === 'Calendar') {
        if (this.$refs['refScheduleCalendar'])
          this.$refs['refScheduleCalendar'].handleQuery()

      } else if (this.mod === 'Table') {
        if (this.$refs['refScheduleTable'])
          this.$refs['refScheduleTable'].handleQuery()
      } else {
        if (this.$refs['refScheduleMineTable'])
          this.$refs['refScheduleMineTable'].handleQuery()
      }
      this.isInitLoading = false

      // this.$notify.info('查询')
      // let searchCondition = this.handleSearchCondition()
      // Object.assign(searchCondition, { order: this.orderSort })
      // let { list } = await this.$api.getLbScheduleMonthList(searchCondition)
      // this.dataList = list
    },
    handleAdd(data) {
      this.currId = null
      // let date = data.date ? data.date : data.fullDate
      if (data) {
        this.defaultFormData = { start_at: null }
      }

      this.open = true
    },
    showLog(row) {
      this.currId = row.id || null
      this.openLog = true
    },
    showNotify(row) {
      this.currId = row.id || null
      this.open = true
    },
    isDing() {
      const ua = window.navigator.userAgent
      return ua.indexOf('DingTalk') !== -1 // true or false
    },
    jumpToPage(pageName, params) {
      // this.$router.push({
      //   name: pageName,
      //   params: params,
      //   query: params
      // })
      if (pageName === 'lbKolDetail' && this.isDing()) {
        this.$router.push({
          name: pageName,
          params: params

        })
      } else {
        const { href } = this.$router.resolve({
          name: pageName,
          params: params
        })
        window.open(href, '_blank')
      }
    },
    selectProduct(row) {
      this.currId = row.id || null
      this.jumpToPage('productSelection', { lb_id: row.id })
    },
    async delInfo(id) {
      let info = await this.$api.delLbSchedule(id)
      if (info) {
        await this.handleQuery()
        this.msgSuccess('删除成功')
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const id = row.id
      this.$confirm(`是否确认删除排期【${row.date}/${row.nickname}/${row.platform_name}】?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delInfo(id)
      }).catch(() => {
        this.msgWarn('取消操作')
      })
    },
    async handleCmd(cmd, row) {
      let canView
      // this.$emit('handleCmd',cmd,this.currentRow)
      switch (cmd) {
        case 'detail':
          // this.$notify.info('查看排期')
          this.jumpToPage('lbKolDetail', { id: row.id })
          break
        case 'selection':
          // this.$notify.info('查看选品')
          this.selectProduct(row)
          break
        // case 'selection_ack':
        //   // this.$notify.info('查看选品-确认')
        //   this.jumpToPage('productSelectionAck', { 'id': row.id })
        //   break
        case 'create':
          // this.$notify.info('添加排期'+JSON.stringify(row))
          this.handleAdd(row)
          break
        case 'log':
          this.showLog(row)
          break
        case 'delete':
          this.handleDelete(row)
          break
        case 'dress':
          if (row.id) {

            canView = await this.isCheckLbDressSelectionView(row.id)
            if (canView) {
              /*      this.dressRow = {
                      id: row.id,
                      nickname: row.nickname,
                      date: row.date,
                      platform_name: row.platform_name
                    }*/
              // this.openDress = true
              let params = {
                id: row.id,
                nickname: row.nickname,
                date: row.date,
                platform_name: row.platform_name
              }

              if (this.isDing()) {
                this.$router.push({
                  name: 'productDressKol',
                  params: params
                })
              } else {
                const { href } = this.$router.resolve({
                  name: 'productDressKol',
                  params: params
                })
                window.open(href, '_blank')
              }
            } else this.$message.error('只有选品排期创建人所在组的成员可以进入')
          } else this.$message.error('暂无服饰选品')
          // this.$notify.info('查看排期')
          break
        // case 'notify':
        //   this.$notify.info('通知')
        //   this.showNotify(row)
        //   break;
      }
    },
    async isCheckLbDressSelectionView(id) {
      let data = await this.$api.checkLbDressSelectionView(id)
      return data.can_view === 1
    },
    // checkLbDressSelectionView
    selectedLeader(item) {
      this.searchCondition.leader_id = item.value
      this.handleQuery()
    },
    dressClose() {
      this.dressRow = {},
        this.openDress = false
    },
  },
  created() {
    this.defaultTime()
  },
  mounted() {
    this.handleQuery()

  }
}
</script>

<style lang='scss' scoped>
@import "../style/main.scss";
</style>

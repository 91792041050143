<template>
  <div style='z-index: 99999' >
    <div v-show='menuData.visible' :style="{ left: menuData.left + 'px', top: menuData.top + 'px' }" class='menu'>
      <div v-if="userPermissions.indexOf('lb_live_create') > -1" class='contextmenu__item'
           @click="checkEvent('create')"><i class='el-icon-plus'></i>&nbsp;&nbsp;添加排期
      </div>
<!--      <div v-if='userPermissions.indexOf("lb_kol_dress_selection_list")>-1' class='contextmenu__item' @click="checkEvent('dress')"><i class='el-icon-user'></i>&nbsp;&nbsp;服饰选品-->
<!--      </div>-->
<!--      v-if="userPermissions.indexOf('lb_kol_dress_original') > -1"-->


      <!--      <div class='contextmenu__item' @click="checkEvent('detail')"  v-if="id && userPermissions.indexOf('lb_schedule_detail') > -1"><i class='el-icon-edit-outline'></i>&nbsp;&nbsp;直播排期详情</div>-->
      <!--      <div class='contextmenu__item' @click="checkEvent('selection')"-->
      <!--           v-if="id && userPermissions.indexOf('lb_schedule_original') > -1"><i-->
      <!--        class='el-icon-shopping-cart-full'></i>&nbsp;&nbsp;直播选品（初选）-->
      <!--      </div>-->
      <!--      <div class='contextmenu__item' @click="checkEvent('selection_ack')"-->
      <!--           v-if="id && userPermissions.indexOf('lb_schedule_ack') > -1"><i-->
      <!--        class='el-icon-shopping-cart-full'></i>&nbsp;&nbsp;直播选品（确认）-->
      <!--      </div>-->

      <!--      <div class='contextmenu__item' @click="checkEvent('log')"-->
      <!--           v-if="id && userPermissions.indexOf('lb_schedule_log') > -1"><i class='el-icon-document'></i>&nbsp;&nbsp;查看日志-->
      <!--      </div>-->
      <!--      <div class='contextmenu__item' @click="checkEvent('delete')"-->
      <!--           v-if="id && userPermissions.indexOf('lb_schedule_delete') > -1"><i class='el-icon-delete'></i>&nbsp;&nbsp;删除排期-->
      <!--      </div>-->
      <!--      <div class='contextmenu__item' @click="checkEvent('delete')"-->
      <!--           v-if="id && userPermissions.indexOf('product_selection_export') > -1"><i class='el-icon-download'></i>&nbsp;&nbsp;下载表格-->
      <!--      </div>-->
      <!--      <div class="contextmenu__item" @click="checkEvent('notify')" v-if="id&&userPermissions.indexOf('lb_schedule_create')>-1">-->
      <!--        <i class="el-icon-chat-line-square"></i> 钉钉通知-->
      <!--      </div>-->
      <!--      <div class="contextmenu__item" @click="checkEvent('4')"-->
      <!--           v-if="id&&userPermissions.indexOf('lb_schedule_create')>-1">-->
      <!--        <i class="el-icon-plus"></i> 复制排期-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ContextmenuTop',
  props: {
    menuData: {
      type: Object,
      default() {
        return {
          visible: false,
          top: 0,
          left: 0
        }
      }
    },
    id: {
      type: [Number, String],
      default() {
        return undefined
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  watch: {
    menuData: {
      deep: true,
      handler() {
        // this.initList()
      }
    }
  },
  data() {
    return {
      menuVisible: false
    }
  },
  methods: {
    closeMenu() {
      this.menuData.visible = false
    },
    checkEvent(val) {
      this.$emit('change', val)
      this.menuData.visible = false
    }
  }
}
</script>

<style scoped>
.contextmenu__item {
  display: block;
  line-height: 34px;
  text-align: left;
  padding-left: 10px;
}

.contextmenu__item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.menu {
  position: absolute;
  background-color: #fff;
  width: 160px;
  /*height: 106px;*/
  font-size: 14px;
  color: #444040;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  white-space: nowrap;
  text-align: left;
  z-index: 1000;
}

.contextmenu__item:hover {
  cursor: pointer;
  background: #ff3176;
  border-color: #ff3176;
  color: #fff;
}
</style>
